import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { StringHelper } from "../../../../utilities/contracts/string-helper";

export class AddressBulkUpdate {
  readonly projectId: number;
  readonly addressIds: string[];
  // ToDo: To change the name later
  readonly notes: string;
  readonly action: number;
  readonly addressBulkActionType: number;
  readonly assignToUserId: number;
  readonly changeRetrievalTypeTo: number;
  readonly vendorId: number;
  readonly tagId: number;
  readonly tagTypeId: number;
  constructor(options: {
    projectId: number;
    addressIds: string[];
    notes: string;
    action: number;
    addressBulkActionType: number;
    assignToUserId: number;
    changeRetrievalTypeTo: number;
    vendorId: number;
    tagId: number;
    tagTypeId: number;
  }) {
    this.projectId = options.projectId;
    this.addressIds = options.addressIds;
    this.notes = StringHelper.clean(options.notes);
    this.action = options.action;
    this.addressBulkActionType = options.addressBulkActionType;
    this.assignToUserId = options.assignToUserId;
    this.changeRetrievalTypeTo = options.changeRetrievalTypeTo;
    this.vendorId = options.vendorId;
    this.tagId = options.tagId;
    this.tagTypeId = options.tagTypeId;
  }
}
