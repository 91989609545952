import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { SubSink } from "subsink";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { FormService } from "../../../../../dynamic-forms/form.service";
import { CheckboxGroup } from "../../../../../dynamic-forms/inputs/checkbox-group/checkbox-group.model";
import { Radiobutton } from "../../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../../dynamic-forms/inputs/selectable-input.model";
import { Textbox } from "../../../../../dynamic-forms/inputs/textbox/textbox.model";
import { GridConfiguration } from "../../../../../shared/grid/models/grid-configuration.model";
import { ArrayHelper } from "../../../../../utilities/contracts/array-helper";
import { DateHelper } from "../../../../../utilities/contracts/date-helper";
import { RegExHelper } from "../../../../../utilities/reg-Ex-Helper";
import { AnalyticsService } from "../../../analytics/analytics.service";
import { RetrievalContactHistory } from "../../retrieval-detail-contact-history.model";
import { RetrievalTimelineItem } from "../../retrieval-timeline-item.model";
import { AddressDetailState } from "../address-detail-state.model";
import { AddressDetailStateService } from "../address-detail-state.service";
import { RetrievalAddressDetailContactHistoryService } from "./address-detail-contact-history.service";

@Component({
  selector: "app-address-detail-contact-history-new",
  templateUrl: "./address-detail-contact-history.component.html",
  styleUrls: ["./address-detail-contact-history.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RetrievalAddressDetailContactHistoryComponent implements OnInit, OnDestroy {

  contactDateInput: Textbox;
  contactMethodTypeInput: CheckboxGroup;
  callResultInput: Radiobutton;
  form: FormGroup;
  formFilterRequest: FormGroup;
  isFiltersVisible = false;
  showErrorMessage = false;

  private sink = new SubSink();
  addressDetailState: AddressDetailState;
  contactHistoryGridConfiguration: GridConfiguration;
  contactHistoryGridData: any[] = [];
  isGridLoaded = false;

  constructor(
    private readonly addressDetailStateService: AddressDetailStateService,
    private retrievalAddressDetailContactHistoryService: RetrievalAddressDetailContactHistoryService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef,
    private readonly formService: FormService,
    private analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    this.retrievalAddressDetailContactHistoryService.setHasCallNotesButton(true);

    this.createForm();
    this.getContactMethodTypes();

    this.sink.add(
      this.addressDetailStateService.state.subscribe(state => {
        this.addressDetailState = state;

        if (this.addressDetailState.hasMasterDocumentSourceId && !this.isGridLoaded) {
          // TODO: Create a contactHistoryStateService so that it is easier to refresh this component.
          this.loadGrid();
        }

        this.changeDetector.markForCheck();
      }),

      this.retrievalAddressDetailContactHistoryService.refreshContactHistory$.subscribe(data => {
        if (data) {
          this.loadGrid();
        }
      })

    );


  }

  createForm() {
    this.contactDateInput =
      new Textbox({
        key: "contact Date",
        label: "Contact Date",
        validators: [Validators.pattern(RegExHelper.dateYearFirstPattern)],
        errorMessages: {
          pattern: "Date should be in YYYY-MM-DD format.",
        },
      });
    this.contactMethodTypeInput =
      new CheckboxGroup({
        key: "contact Type",
        label: "Contact Type",
        appendTo: "body",
      });
    this.callResultInput =
      new Radiobutton({
        key: "call Result",
        label: "Call Result",
        options: this.getCallResults(),
      });

    this.form = this.formService.createFormGroup([
      this.contactDateInput,
      this.contactMethodTypeInput,
      this.callResultInput,
    ]);
  }

  private getContactMethodTypes(): void {
    this.analyticsService
      .getContactMethodTypes("")
      .subscribe(options => {
        this.contactMethodTypeInput = { ...this.contactMethodTypeInput, options } as any;
        this.formService.updateDom.next();
      });
  }

  private getCallResults(): SelectableInput[] {
    return [
      new SelectableInput({ text: "Success", value: "Success", extra: "1" }),
      new SelectableInput({ text: "Fail", value: "Fail", extra: "0" }),
    ];
  }

  ngOnDestroy() {
    this.retrievalAddressDetailContactHistoryService.setHasCallNotesButton(false);
    this.sink.unsubscribe();
  }

  loadGrid(): void {
    this.isGridLoaded = true;
    const contactHistoryModel = new RetrievalContactHistory({
      masterDocumentSourceId: this.addressDetailState.masterDocumentSourceId,
      contactDate: this.form.get("contact Date").value,
      contactMethodTypesCsv: this.contactMethodTypeInput.selectedOptions?.map(s => s.value).join(","),
      callResult: this.getCallResults().find(x => x.value === this.form.get("call Result").value)?.extra,
    });
    this.retrievalAddressDetailContactHistoryService
      .getContactHistoryList(contactHistoryModel)
      .subscribe(this.assignAndNotify);
  }

  assignAndNotify = <T>(data: RetrievalContactHistory[]): void => {
    this.contactHistoryGridData = this.mapToGroupedContactHistoryItems(data);
    this.formFilterRequest = this.form;
    this.changeDetector.markForCheck();
  }

  private mapToGroupedContactHistoryItems(data: RetrievalContactHistory[]): any[] {
    const contactHistoryList = data.reduce((contactHistoryGroup, contactHistoryItem) => {
      const specificDay = DateHelper.format(contactHistoryItem.contactDate);
      contactHistoryGroup[specificDay] = contactHistoryGroup[specificDay] || [];
      contactHistoryGroup[specificDay].push(contactHistoryItem);
      return contactHistoryGroup;
    },                                     {});

    const groupedTimelines = Object.keys(contactHistoryList).map(specificDay => {
      return new RetrievalTimelineItem({
        specificDay,
        timelineGroup: contactHistoryList[specificDay],
      });
    });
    return groupedTimelines;
  }

  retryFax(contactLogId: number): void {
    this.retrievalAddressDetailContactHistoryService
      .retryFax(contactLogId, this.addressDetailState.contact.contactFax)
      .subscribe(response => {
        if (response === "No provider packet available.") {
          this.messagingService.showToast(response, SeverityType.INFO);
        }
        this.loadGrid();
      });
  }

  trackByIndex(index, item) {
    return index;
  }

  resetAllFilters(): void {
    this.form.reset();
  }

  showFilters(): void {
    this.isFiltersVisible = true;
  }

  closeFilters(): void {
    if (this.isValidForm) {
      this.isFiltersVisible = false;
      this.showErrorMessage = false;
      this.loadGrid();
    } else { this.showErrorMessage = true; }
  }

  get isValidForm(): boolean {
    let result = true;
    if (this.form.invalid) {
      this.formService.markAllAsTouched(this.form);
      this.changeDetector.markForCheck();
      result = false;
    }
    return result;
  }

  onRemoveFilter(event: FormGroup) {
    this.loadGrid();
  }

  get totalRecords(): number {
    let recordCount = 0;
    if (ArrayHelper.isAvailable(this.contactHistoryGridData)) {
      this.contactHistoryGridData.forEach(element => {
        if (ArrayHelper.isAvailable(element.timelineGroup)) {
          recordCount += element.timelineGroup.length;
        }
      });
    }
    return recordCount;
  }
}
