  <app-modal [(visible)]="isChangeOwnerVisible" header="CHANGE OWNER AND ASSIGN" (visibleChange)="visibleChange($event)">
    <form [formGroup]="formGroup">
      <div class="ui-g ui-fluid basic-filter">
        <div class="ui-g-12 ui-md-12 ui-lg-12 modal-label">New Owner</div>
        <div class="ui-g-12 ui-md-12 ui-lg-12 change-owner-select">
          <app-button class="header-button" text="REVELEER" [styleClass]="hdviButtonStyle" (onClick)="getUsersDropdown('O')"></app-button>&nbsp;&nbsp;
          <app-button class="header-button" text="CLIENT" [styleClass]="clientButtonStyle" (onClick)="getUsersDropdown('C')"></app-button>
        </div>
        <div class="ui-g-6 ui-md-6 ui-lg-6">          
          <form-factory [formGroup]="formGroup" [model]="assignedUsers"></form-factory>
        </div>
        <div class="ui-g-12 ui-md-12 ui-lg-12">
          <form-factory [formGroup]="formGroup" [model]="notes"></form-factory>
        </div>
      </div>
    </form>
    <footer>
      <app-button class="header-button" text="FINISH" (onClick)="changeOwner()"></app-button>
    </footer>
</app-modal>
