import { List } from "immutable";
import { StringHelper } from "../../utilities/contracts/string-helper";
import { AlertNotification } from "./alert-notification.model";

export class NotificationItem {
  readonly specificDay: string;
  readonly NotificationGroup: List<AlertNotification>;

  constructor(options: {
    specificDay: string;
    notificationGroup: List<AlertNotification>;
  }) {
    this.specificDay = StringHelper.clean(options.specificDay);
    this.NotificationGroup = options.notificationGroup;
  }
}

