import { List } from "immutable";

export class CacheTracker {
  private times = List([]);
  private currentIndex = 0;


  constructor(private cacheSize: number = 1000) { }


  get length(): number {
    return this.times.size;
  }

  get currentTime(): number {
    return this.times.get(this.currentIndex);
  }

  get isLimitReached(): boolean {
    return this.length >= this.cacheSize;
  }

  add(time: number): void {
    this.times = this.times.set(this.currentIndex, time);
    this.incrementCurrentTimeIndex();
  }


  private incrementCurrentTimeIndex(): void {
    this.currentIndex = (this.currentIndex + 1) % this.cacheSize;
  }
}
