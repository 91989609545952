import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { SubSink } from "subsink";
import { UserToken } from "../../../../../../../auth/user-token.model";
import { MessagingService } from "../../../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../../../../core/user/user.service";
import { FormService } from "../../../../../../../dynamic-forms/form.service";
import { Switch } from "../../../../../../../dynamic-forms/inputs/switch/switch.model";
import { Resize } from "../../../../../../../dynamic-forms/inputs/textarea/resize.enum";
import { Textarea } from "../../../../../../../dynamic-forms/inputs/textarea/textarea.model";
import { ButtonAction } from "../../../../../../../shared/button/button-action.model";
import { GridColumnDefinition } from "../../../../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../../../../shared/grid/models/grid-configuration.model";
import { ArrayHelper } from "../../../../../../../utilities/contracts/array-helper";
import { StringHelper } from "../../../../../../../utilities/contracts/string-helper";
import { OverreadFeedbackDetail } from "../overread-feedback-detail.model";
import { OverreadFeedbackQueryItem } from "../overread-feedback-query-item.model";
import { OverreadFeedbackStatusChange } from "../overread-feedback-status-change.model";
import { OverreadFeedbackStatus } from "../overread-feedback-status.enum";
import { OverreadFeedbackService } from "../overread-feedback.service";

@Component({
  selector: "member-challenge-modal",
  templateUrl: "./challenge-modal.component.html",
  styleUrls: ["./challenge-modal.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChallengeModalComponent implements OnInit, OnDestroy {
  @Input() chaseId: number;
  @Input() isRisk20 = false;
  @Input() chaseOverreadDetail: any;
  @Output() onWorkflowStatusToggle = new EventEmitter<boolean>();
  @ViewChild("reviewColumn", { static: true }) reviewColumn: TemplateRef<any>;
  private sink = new SubSink();
  private user: UserToken;
  reasonForm: FormGroup;
  feedbackForm: FormGroup;
  toggleForm: FormGroup;
  dataEntryToggle: Switch;
  reasonText: Textarea[];
  feedbackText: Textarea[];
  splitButtonOptions: ButtonAction[][] = [];
  gridConfiguration = new GridConfiguration();
  abstractorGridConfiguration = new GridConfiguration();
  overreaderGridConfiguration = new GridConfiguration();
  expandedRows = {};
  gridData: OverreadFeedbackDetail[] = [];
  nextOverreadFeedback: OverreadFeedbackQueryItem[] = [];
  abstractorFeedbackIndex: number[] = [];
  overreaderFeedbackIndex: number[] = [];
  challengeClassIndex: number[] = [];
  visible = false;
  @Input() isRiskOrIva: boolean;
  isGetNextAvailable = true;
  constructor(
    private router: Router,
    private formService: FormService,
    private overreadFeedbackService: OverreadFeedbackService,
    private readonly messagingService: MessagingService,
    private userService: UserService,
    private readonly changeDetector: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.user = this.userService?.getUserToken();
    this.createToggleForm();
    this.createGrids();

    if (ArrayHelper.isAvailable(this.chaseOverreadDetail)) {
      const managerGridData = this.chaseOverreadDetail.filter(x => x.status === OverreadFeedbackStatus.MANAGER);
      if (ArrayHelper.isAvailable(managerGridData)) {
        if (this.isRiskOrIva) {
          managerGridData.forEach(element => {
            element.abstractorData = element.abstractorData.replace(new RegExp("\n", "g"), "\n\n");
            if (StringHelper.isAvailable(element.abstractorData)) {
              element.abstractorData = `1. ${element.abstractorData}`;
              let numberedData = "";
              element.abstractorData.split("\n\n").forEach((splitData, i) => {
                if (i) {
                  numberedData += `\n\n${(i + 1)}. ${splitData}`;
                } else {
                  numberedData = splitData;
                }
              });
              element.abstractorData = numberedData;
            }
            element.overreaderData = element.overreaderData.replace(new RegExp("\n", "g"), "\n\n");
            if (StringHelper.isAvailable(element.overreaderData)) {
              element.overreaderData = `1. ${element.overreaderData}`;
              let numberedData = "";
              element.overreaderData.split("\n\n").forEach((splitData, i) => {
                if (i) {
                  numberedData += `\n\n${(i + 1)}. ${splitData}`;
                } else {
                  numberedData = splitData;
                }
              });
              element.overreaderData = numberedData;
            }
          });
        }
        this.gridData = managerGridData;
        this.visible = true;
        this.createGridForm();
        this.openRow(this.gridData[0].chaseOverreadFeedbackId);
        this.changeDetector.markForCheck();
      }
    }

  }

  ngOnDestroy() {
    this.sink.unsubscribe();
  }

  onToggle(event: boolean): void {
    this.onWorkflowStatusToggle.emit(event);
  }

  onOpenReview(event: MouseEvent, id: number): void {
    event.stopPropagation();
    this.toggleReviewButton(id);
  }

  onAbstractorAgree(id: number): void {
    if (this.abstractorFeedbackIndex.indexOf(id) === -1) {
      this.abstractorFeedbackIndex.push(id);
    }
    if (this.overreaderFeedbackIndex.indexOf(id) > -1) {
      const index = this.overreaderFeedbackIndex.indexOf(id);
      this.overreaderFeedbackIndex.splice(index, 1);
    }
  }

  onOverreaderAgree(id: number): void {
    if (this.overreaderFeedbackIndex.indexOf(id) === -1) {
      this.overreaderFeedbackIndex.push(id);
    }
    if (this.abstractorFeedbackIndex.indexOf(id) > -1) {
      const index = this.abstractorFeedbackIndex.indexOf(id);
      this.abstractorFeedbackIndex.splice(index, 1);
    }
  }

  onFeedbackSubmit(id: number): void {
    const request = new OverreadFeedbackStatusChange({
      chaseOverreadFeedbackId: id,
      overreadFeedbackStatus: OverreadFeedbackStatus.COMPLETED,
      commentText: this.feedbackForm.get(id.toString()).value,
      abstractorReceiptStatus: OverreadFeedbackStatus.REQUESTED,
      overreaderReceiptStatus: OverreadFeedbackStatus.REQUESTED,
      correctDataset: this.getCorrectDataset(id),
    });

    this.closeRow(id);
    this.removeGridRow(id);
    this.updateStatusChange(request);
  }

  onClose(): void {
    this.visible = false;
  }

  onGetNext(): void {
    const getNextChase = {
      getNextFilter: 1,
      startRecord: 1,
      endRecord: 1,
    };

    this.overreadFeedbackService.getOverreadFeedbackList(getNextChase).subscribe(nextOverreadFeedback => {
      this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      if (ArrayHelper.isAvailable(nextOverreadFeedback)) {
        this.router.navigate(["members", "chase", nextOverreadFeedback[0].chaseId]);
      } else {
        this.isGetNextAvailable = false;
        const errorMessage = "All overread feedback chases have been completed.";
        this.messagingService.showToast(errorMessage, SeverityType.ERROR);
        this.changeDetector.markForCheck();
      }
    });
  }

  reviewButtonClass(id: number): string {
    return (this.challengeClassIndex.indexOf(id) > -1) ? "review-btn--active" : "review-btn";
  }

  rowModel(id: number): Textarea {
    return this.reasonText.find(item => item.key === id.toString());
  }

  feedbackRowModel(id: number): Textarea {
    return this.feedbackText.find(item => item.key === id.toString());
  }

  splitButtonModel(id: number): ButtonAction[] {
    return this.splitButtonOptions.find(item => item.find(btn => btn.key === id.toString()));
  }

  splitButtonText(id: number): string {
    if (this.abstractorFeedbackIndex.indexOf(id) > -1) {
      return this.isRiskOrIva ? "Agree with Coder" : "Agree with Abstractor";
    }
    if (this.overreaderFeedbackIndex.indexOf(id) > -1) {
      return "Agree with Overreader";
    }
    return "SELECT NEXT ACTION";
  }

  isFeedbackRowOpen(id: number): boolean {
    return (this.abstractorFeedbackIndex.indexOf(id) > -1 || this.overreaderFeedbackIndex.indexOf(id) > -1);
  }

  isFeedbackRowModelValid(id: number): boolean {
    return this.feedbackForm.get(id.toString()).valid;
  }

  getTemplateData(rowData: string): any[] {
    const templateData = [];
    rowData.split("CHAR(13)+CHAR(10)").map(gridData => {
      templateData.push({ gridData });
    });

    return templateData;
  }

  onRowClick(event: OverreadFeedbackDetail) {
    this.expandedRows[event.chaseOverreadFeedbackId] ? this.closeRow(event.chaseOverreadFeedbackId) : this.openRow(event.chaseOverreadFeedbackId);
  }


  get feedbackCompleted(): boolean {
    return this.gridData.length === 0;
  }

  private removeGridRow(id: number): void {
    const index = this.gridData.findIndex(item => item.chaseOverreadFeedbackId === id);
    this.gridData.splice(index, 1);
    this.changeDetector.markForCheck();

    if (ArrayHelper.isAvailable(this.gridData)) {
      this.openRow(this.gridData[0].chaseOverreadFeedbackId);
    }
  }

  private openRow(id: number): void {
    this.expandedRows[id] = true;
  }

  private closeRow(id: number): void {
    this.expandedRows[id] = false;
  }

  private toggleReviewButton(id: number): void {
    if (this.challengeClassIndex.indexOf(id) === -1) {
      this.challengeClassIndex.push(id);
      this.openRow(id);
    } else if (this.challengeClassIndex.indexOf(id) > -1) {
      const index = this.challengeClassIndex.indexOf(id);
      this.challengeClassIndex.splice(index, 1);
      this.closeRow(id);
    }
  }

  private createGrids(): void {
    this.gridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "entityTypeName", header: this.isRiskOrIva ? "Feedback" : "Event Type", width: "75%" }),
        new GridColumnDefinition({ template: this.reviewColumn, isSortableColumn: false, header: "Challenges", width: "25%" }),
      ],
      pageSize: 100,
      rowExpandMode: "multiple",
      showActionColumn: false,
      showMenu: false,
      trackByField: "chaseOverreadFeedbackId",
      expandedRows: this.expandedRows,
      hasPagination: false,
    });

    this.abstractorGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "gridData", header: this.isRiskOrIva ? "Coder Entered" : "Abstractor Entered", isSortableColumn: false }),
      ],
      pageSize: 100,
      showActionColumn: false,
      showMenu: false,
      hasPagination: false,
    });

    this.overreaderGridConfiguration = new GridConfiguration({
      columns: [
        new GridColumnDefinition({ field: "gridData", header: "Overreader Entered", isSortableColumn: false }),
      ],
      pageSize: 100,
      showActionColumn: false,
      showMenu: false,
      hasPagination: false,
    });
  }

  getIvaRiskStyle(data: string) {
    return !StringHelper.isAvailable(data) ? "none" : "";
  }

  private createToggleForm(): void {
    this.dataEntryToggle = new Switch({
      key: "switch",
      label: "Show Original Data Entry",
    });

    this.toggleForm = this.formService.createFormGroup([this.dataEntryToggle]);
  }

  private createGridForm(): void {
    this.reasonText = this.gridData.map(item => new Textarea({
      key: (item.chaseOverreadFeedbackId).toString(),
      label: this.isRiskOrIva ? "REASON FOR CHALLENGE" : "REASON FOR CHALLENGE (include page number)",
      value: item.abstractorComment,
      rows: 2,
      resize: Resize.VERTICAL,
      readonly: true,
    }));

    this.feedbackText = this.gridData.map(item => new Textarea({
      key: (item.chaseOverreadFeedbackId).toString(),
      label: "MANAGER FEEDBACK",
      placeholder: "Enter Text Here",
      rows: 2,
      resize: Resize.VERTICAL,
      validators: [Validators.required, Validators.minLength(4), Validators.maxLength(1000)],
      errorMessages: {
        required: "Write a description between 4 - 1000 characters.",
        minlength: "Write a description between 4 - 1000 characters.",
        maxlength: "Write a description between 4 - 1000 characters.",
      },
    }));

    this.splitButtonOptions = this.gridData.map(item => [
      new ButtonAction({
        key: (item.chaseOverreadFeedbackId).toString(),
        name: this.isRiskOrIva ? "Agree with Coder" : "Agree with Abstractor",
        action: () => this.onAbstractorAgree(item.chaseOverreadFeedbackId),
      }),
      new ButtonAction({
        key: (item.chaseOverreadFeedbackId).toString(),
        name: "Agree with Overreader",
        action: () => this.onOverreaderAgree(item.chaseOverreadFeedbackId),
      }),
    ]);

    this.reasonForm = this.formService.createFormGroup(this.reasonText);
    this.feedbackForm = this.formService.createFormGroup(this.feedbackText);
  }

  private updateStatusChange(request: OverreadFeedbackStatusChange): void {
    this.overreadFeedbackService.updateOverreadFeedbackStatus(request).subscribe(() => {
      this.messagingService.showToast(`Updated successfully.`, SeverityType.SUCCESS);
    });
  }

  private getCorrectDataset(id: number): string {
    return this.abstractorFeedbackIndex.indexOf(id) > -1 ? "Abstractor" : "Overreader";
  }

}
