import { BehaviorSubject } from "rxjs";
import { DocumentPage } from "../../../platform/modules/retrieval/retreival-document-review/document-page.model";
import { ArrayHelper } from "../../../utilities/contracts/array-helper";
import { NumberHelper } from "../../../utilities/contracts/number-helper";

export class DocumentViewerState {
  private pPages = new BehaviorSubject<(DocumentPage | null)[]>([]);
  private pCurrentPageNumber: number;
  private pTotalPages: number;
  private pDocumentTypeId: number;
  private pCurrentDocumentQueueId: number;
  documentId: number;
  minLoadThreshold = 10;
  maxLoadThreshold = 13;


  constructor(model: Partial<DocumentViewerState> = {}) {
    this.setPages(model.pages);
    this.totalPages = model.totalPages;
    this.currentPageNumber = model.currentPageNumber;
    this.documentId = model.documentId;
    this.documentTypeId = model.documentTypeId;
  }


  get documentTypeId(): number {
    if (this.pDocumentTypeId == null && this.hasPage(1)) {
      this.documentTypeId = this.getPage(1).documentTypeId;
    }

    return this.pDocumentTypeId;
  }
  set documentTypeId(value: number) {
    this.pDocumentTypeId = value == null ? null : Number(value);
  }

  get totalPages(): number {
    if (NumberHelper.isLessThan(this.pTotalPages, 1) && this.hasPage(1)) {
      this.totalPages = this.getPage(1).numberOfPages;
    }

    return this.pTotalPages;
  }
  set totalPages(value: number) {
    this.pTotalPages = NumberHelper.isGreaterThan(value, 0) ? value : 0;
  }

  get pages$(): BehaviorSubject<(DocumentPage | null)[]> {
    return this.pPages;
  }
  get pages(): (DocumentPage | null)[] {
    return this.pPages.value;
  }
  private setPages(value: (DocumentPage | null)[]): void {
    const pages = ArrayHelper.isAvailable(value) ? value : [];
    this.pPages.next(pages);
  }

  get currentPageNumber(): number {
    return this.pCurrentPageNumber;
  }
  set currentPageNumber(value: number) {
    if (!NumberHelper.isAvailable(value)) {
      this.pCurrentPageNumber = null;
    } else if (NumberHelper.isGreaterThan(value, this.totalPages, true)) {
      this.pCurrentPageNumber = this.totalPages;
    } else if (NumberHelper.isLessThan(value, 1, true)) {
      this.pCurrentPageNumber = 1;
    } else {
      this.pCurrentPageNumber = value;
    }
  }

  get currentDocumentQueueId(): number {
    return this.pCurrentDocumentQueueId;
  }
  set currentDocumentQueueId(value: number) {
    if (!NumberHelper.isAvailable(value)) {
      this.pCurrentDocumentQueueId = null;
    } else if (NumberHelper.isLessThan(value, 1, true)) {
      this.pCurrentDocumentQueueId = null;
    } else {
      this.pCurrentDocumentQueueId = value;
    }
  }

  get hasCurrentPage(): boolean {
    return this.currentPage != null;
  }

  get currentPage(): DocumentPage {
    return this.getPage(this.currentPageNumber);
  }

  get hasFirstPage(): boolean {
    return this.hasPage(1);
  }

  get hasLastPage(): boolean {
    return this.hasFirstPage && this.hasPage(this.totalPages);
  }

  get hasAllPages(): boolean {
    throw new Error("Not Implemented Exception");
  }

  get shouldLoadMore(): boolean {
    let min = this.currentPageNumber - this.minLoadThreshold - 1;
    min = min < 1 ? 1 : min;
    let max = this.currentPageNumber + this.minLoadThreshold;
    max = max > this.totalPages ? this.totalPages : max;
    let loadMorePages = false;
    while (!loadMorePages && min <= max) {
      loadMorePages = !this.hasPage(min);
      min++;
    }
    return loadMorePages;
  }

  hasPage(pageNumber: number): boolean {
    return this.getPage(pageNumber) != null;
  }

  getPage(pageNumber: number): DocumentPage {
    return this.pages[pageNumber - 1];
  }

  setPage(page: DocumentPage): void {
    this.pages[page.pageNumber - 1] = page;
  }

  removeUndefinedPage(): void {
    let undefinedPageCount = 0;
    this.pages.forEach((element, index) => {
      if (element === undefined) {
        if (index > -1 && this.pages[index + 1] === undefined) {
          this.pages.splice(index, 2);
          undefinedPageCount += 2;
        } else if (index > -1) {
          this.pages.splice(index, 1);
          undefinedPageCount++;
        }
      }
    });
    this.totalPages = this.totalPages - undefinedPageCount;
  }

  removePages(begPage: number, endPage: number): void {
    // TODO: How does this sync up with the database?

    // update totalPages
    const cleanBegPage = Number(begPage);
    const cleanEndPage = Number(endPage);
    const numberOfPagesDeleted = cleanEndPage - cleanBegPage + 1;
    this.totalPages -= numberOfPagesDeleted;

    // remove and renumber pages
    const newPages = this.pages.reduce((newDocumentPages, page) => {
      if (page) {
        const pageNumber = page.pageNumber;
        const isBeforeRemove = NumberHelper.isLessThan(pageNumber, cleanBegPage);
        const isAfterRemove = NumberHelper.isGreaterThan(pageNumber, cleanEndPage);
        if (isAfterRemove) {
          page.pageNumber = page.pageNumber - numberOfPagesDeleted;
        }

        if (isBeforeRemove || isAfterRemove) {
          newDocumentPages.push(page);
        }

        return newDocumentPages;
      }
    },                                 [] as DocumentPage[]);

    this.setPages(newPages);

    // update current page number
    if (NumberHelper.isGreaterThan(this.currentPageNumber, cleanBegPage, true) && NumberHelper.isLessThan(this.currentPageNumber, cleanEndPage, true)) {
      this.currentPageNumber = cleanBegPage - 1;
    } else if (NumberHelper.isGreaterThan(this.currentPageNumber, cleanEndPage)) {
      this.currentPageNumber = this.currentPageNumber - numberOfPagesDeleted;
    }
  }

  getPagesToLoad(): number[] {
    const currentPageNumber = this.currentPageNumber;
    const maxThreshold = this.hasPage(currentPageNumber) ? this.maxLoadThreshold : this.minLoadThreshold;

    let min = currentPageNumber - maxThreshold;
    min = min < 1 ? 1 : min;

    let max = currentPageNumber + maxThreshold;
    max = max > this.totalPages ? this.totalPages : max;

    const pages = [];
    for (let i = min; i <= max; ++i) {
      if (!this.hasPage(i)) {
        pages.push(i);
      }
    }

    return pages;
  }
}
