import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../core/automapper/automapper.service";
import { MessagingService } from "../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../core/messaging/severity-type.enum";
import { UserService } from "../../../../core/user/user.service";
import { FormService } from "../../../../dynamic-forms/form.service";
import { Dropdown } from "../../../../dynamic-forms/inputs/dropdown/dropdown.model";
import { Radiobutton } from "../../../../dynamic-forms/inputs/radiobutton/radiobutton.model";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { BulkAction } from "../../../../shared/grid/bulk-actions/bulk-action.model";
import { GridPipeName } from "../../../../shared/grid/grid-pipe.enum";
import { GridColumnDefinition } from "../../../../shared/grid/models/grid-column-definition.model";
import { GridConfiguration } from "../../../../shared/grid/models/grid-configuration.model";
import { LookupModel } from "../../../../shared/models/lookup-model";
import { NumberHelper } from "../../../../utilities/contracts/number-helper";
import { RetrievalPageService } from "../../retrieval/retrieval-page/retrieval-page.service";
import { ProjectDocumentType } from "../project-files/project-document-type.enum";
import { ProjectFileService } from "../project-files/project-file.service";
import { ProjectFile } from "../project-files/project-file/project-file.model";
import { ProjectType } from "../project-type.enum";
import { DataExtractService } from "./data-extract.service";

@Component({
    selector: "app-data-extract",
    templateUrl: "./data-extract.component.html",
    styleUrls: ["./data-extract.component.scss"],
    providers: [ProjectFileService, RetrievalPageService, DataExtractService],
})
export class DataExtractComponent implements OnInit {

    gridConfigurationModel = new GridConfiguration();
    projectId: number;
    projectFiles: ProjectFile[] = [];
    private userId: number;
    readonly projectDocumentTypeId = ProjectDocumentType.DataExport;
    projectList: LookupModel[] = [];
    isConfirmationModalVisible = false;
    isFileDelete: boolean;
    deleteDataExtractFile: any;
    actions: BulkAction[];
    form: FormGroup;
    projectInput = new Dropdown({
        key: "id",
        label: "Project",
        placeholder: "Select...",
        validators: [Validators.required],
        errorMessages: {
            required: "Project is required.",
        },
    });

    includeHeaderInput = new Radiobutton({
        key: "headers",
        label: "Include Headers",
        value: 1,
        options: [
            new SelectableInput({ text: "Yes", value: 1 }),
            new SelectableInput({ text: "No", value: 0 }),
        ],
        validators: [Validators.required],
    });

    delimiterInput = new Dropdown({
        key: "delimiter",
        label: "Delimiter",
        value: "Tab",
        options: [
            new SelectableInput({ text: "Tab", value: "Tab" }),
            new SelectableInput({ text: "Pipe", value: "Pipe" }),
            new SelectableInput({ text: "Comma", value: "Comma" }),
        ],
    });

    get isProjectSelected(): boolean {
        return NumberHelper.isGreaterThan(this.projectId, 0);
    }

    get projectName(): string {
        const selectedOption = this.projectInput.getSelectedOption(this.form);
        return selectedOption ? selectedOption.text : "";
    }

    constructor(
        protected readonly automapper: AutomapperService,
        protected readonly formService: FormService,
        private readonly projectFileService: ProjectFileService,
        private messagingService: MessagingService,
        private userService: UserService,
        private retrievalPageService: RetrievalPageService,
        private dataExtractService: DataExtractService,
        private readonly changeDetector: ChangeDetectorRef,
        private route: ActivatedRoute
    ) {
        this.userId = this.userService.getUserToken().userId;
        this.gridConfigurationModel.columns = [
            new GridColumnDefinition({ field: "jobId", header: "Job ID" }),
            new GridColumnDefinition({ field: "projectName", header: "Project Name" }),
            new GridColumnDefinition({ field: "projectType", header: "Project Type" }),
            new GridColumnDefinition({ field: "chaseCount", header: "Total Chases" }),
            new GridColumnDefinition({ field: "totalLineCount", header: "Total Lines" }),
            new GridColumnDefinition({ field: "extractType", header: "Extract Type" }),
            new GridColumnDefinition({ field: "filename", header: "File Name" }),
            new GridColumnDefinition({ field: "username", header: "Requested by" }),
            new GridColumnDefinition({ field: "lastmodified", header: "Request Date", pipeName: GridPipeName.Date, format: "MM-dd-yyyy hh:mm a" }),
            new GridColumnDefinition({ field: "status", header: "Status" }),
        ];
        this.gridConfigurationModel.pageSize = 25;
        this.gridConfigurationModel.showActionColumn = true;
        this.gridConfigurationModel.showExportAction = false;
        this.actions = this.getActions();
    }

    ngOnInit() {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.projectId = +params.get("projectId");
        });
        this.createForm();
        this.getProjectList();
    }

    private getActions(): BulkAction[] {
        const totalBulkActions = [
          new BulkAction({
            name: "View File",
            action: this.onViewFile.bind(this),
          }),
          new BulkAction({
            name: "Delete File",
            action: this.showDeleteWarning.bind(this),
          }),
        ];
        return totalBulkActions;
      }

    protected createForm(): void {
        this.form = this.formService.createFormGroup([
            this.projectInput, this.includeHeaderInput, this.delimiterInput,
        ]);
    }

    getProjectList() {
        const projectTypeIds = `${ProjectType.HEDIS} , ${ProjectType.RISK} , ${ProjectType.IVA} , ${ProjectType.ADDRESS_VERIFICATION}`;
        this.retrievalPageService
            .getProjectList(null, null, null, projectTypeIds)
            .pipe(map(this.automapper.curryMany("LookupModel", "SelectableInput")))
            .subscribe(result => {
                this.projectInput = new Dropdown({ ...this.projectInput, options: result } as any);
                if (this.isProjectSelected) {
                    this.form.get(this.projectInput.key).setValue(this.projectId);
                    this.loadGrid();
                }
                this.changeDetector.markForCheck();
            });
    }

    onProjectChange(): void {
        this.projectId = this.form.get(this.projectInput.key).value;
        this.loadGrid();
    }

    loadGrid() {
        this.projectFileService
            .getDataExtractFiles(this.projectId)
            .subscribe(items => {
                this.projectFiles = items;
                this.changeDetector.markForCheck();
            });
    }

    showHideConfirmationModal(isVisible: boolean): void {
        this.isConfirmationModalVisible = isVisible;
    }

    onViewFile(rowData): void {
      this.projectFileService.downloadFile2(rowData.projectdocumentid, this.userId, "dataExtract", this.projectDocumentTypeId);
    }

    onDeleteFile(): void {
        this.isFileDelete = false;

        this.projectFileService.deleteProjectChartFile(this.deleteDataExtractFile).subscribe(() => {
            this.messagingService.showToast(`${this.deleteDataExtractFile.Filename} has been deleted successfully.`, SeverityType.SUCCESS);
            this.loadGrid();
        });
    }

    processRequest() {
        const dataExportRequest = {
            ProjectId: this.projectId,
            IncludeHeaders: this.form.get(this.includeHeaderInput.key).value,
            DelimiterType: this.form.get(this.delimiterInput.key).value,
        };
        this.dataExtractService.generateDataExtract(dataExportRequest).subscribe(() => {
            this.messagingService.showToast("Request has been generated successfully.", SeverityType.SUCCESS);
            this.loadGrid();
        });
    }

    showDeleteWarning(rowData: any): void {
        this.isFileDelete = true;
        this.deleteDataExtractFile = {
            Filename: rowData.filename,
            ProjectId: this.projectId,
            ProjectDocumentId: rowData.projectdocumentid,
            FileType: "dataExtract",
        };
      }

}
