export enum HideFilters {
    ABSTRACTION_DATE = "Abstraction_Date",
    ABSTRACTOR_BY = "Abstractor_By",
    AID = "AID",
    AID_GROUP = "AID_Group",
    CLIENT_NAME = "Client_Name",
    CODED_BY = "Coded_By",
    COMPLETION_DATE = "Completion_Date",
    CONFIRMATION_NUMBER = "Confirmation_Number",
    CONTACT_METHOD_ID = "Contact_Method_Type_ID",
    CONTACT_METHOD_STATUS = "Contact_Method_Status",
    CONTRACT_NUMBER = "Contract_Number",
    CO_RETRIEVAL = "Co-Retrieval ",
    CO_RETRIEVAL2 = "Co-Retrieval",
    CO_RETRIEVAL3 = "Co_Retrieval ",
    CO_RETRIEVAL4 = "Co-Retrieval+",
    CO_RETRIEVAL5 = "Co_Retrieval",
    DOCUMENT_ID = "Document_ID",
    DOCUMENT_STATE = "Document_State_Group",
    END_DATE = "End Date",
    EXPECTED_RETRIEVAL = "Expected_Retrieval_Method",
    EXPECTED_RETRIEVAL2 = "Expected_Retrieval",
    GROUP_NAME = "Group_Name",
    HEALTH_PLAN = "Health_Plan",
    HEALTH_PLAN2 = "Health_Plan ",
    HEALTH_PLAN3 = "Health_Plan+",
    IS_TEST_PROJECT = "Is+Test+Project",
    LAST_DOS_OTHER = "Last_DOS_Other_Provider_Date",
    LAST_DOS_PCP = "Last_DOS_PCP_Date",
    LINE_OF_BUSINESS = "Line_Of_Business",
    MEASURE = "Measure+",
    MEASURE2 = "Measure",
    MEASURE_CODE = "Measure Code",
    MOVE_DATE = "Move_Date",
    MOVEBACK_BY = "Moveback_By",
    MOVEBACK_DATE = "Moveback_Date",
    ORGANIZATION_ID = "Organization_ID",
    ORG_ID = "Org_ID",
    ORGANIZATION_NAME = "Organization_Name",
    OVERREAD2_BY = "Overread2_By",
    OVERREAD2_DATE = "Overread2 Date",
    PAYMENT_YEAR = "Payment_Year",
    PCP_STATE = "PCP_State",
    PRODUCT = "Product",
    PROJECT_ID = "Project_ID",
    PROJECT_ID2 = "Project ID",
    PROJECT_NAME = "Project+Name",
    PROJECT_NAME2 = "Project_Name",
    PROJECT_TYPE_ID = "Project Type ID",
    PRIORITY = "Priority",
    PROVIDER_GATEWAY_PIN = "Provider_Gateway_Pin",
    RAF_CALCS_SOURCE = "RAF_Calcs_Source",
    SERVICE_YEAR = "Service_Year",
    START_DATE = "Start_Date",
    SUSPECT_HCC = "Suspect_HCC_Source",
    USER_ID = "User_ID",
    USER_ID2 = "user_id",
    USER_NAME = "User_Name",
    UPDATED_BY = "Updated_By",
    UPDATE_DATE = "Update_Date",
    VALIDATION_RESULT = "Validation+Result",
    WORKFLOW = "Workflowstatus_Name",
    TEMPLATE_ID= "Template ID",
    HEALTH_PLAN4= "Health Plan",
    CONTRACT_NUMBER2= "Contract Number",
    CO_RETRIVAL_OWNER= "Co Retrieval Owner",
    LINE_OF_BUSINESS2= "Line Of Business",
    PROJECT_TYPE_ID2 = "Project_Type_ID",
    TEMPLATE_ID2 = "Template_ID",
    CUSTOMER_NAME = "Customer_Name",
    RETRIVAL_OWNER = "Retrieval_Owner",
    PRODUCT_NAME = "Product_Name",
}
