import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { BASE_API_URL } from "../../../../core/environment.tokens";
import { SelectableInput } from "../../../../dynamic-forms/inputs/selectable-input.model";
import { ContactRecord } from "../psr/address-detail/address-detail-contact-record";
import { CallNotesModel } from "../retrieval-call-flow/callNotes-model";

@Injectable({
  providedIn: "root",
})
export class CallFlowService {
  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient
  ) { }

  getCallTypesDropdown(isCallSuccess: boolean): Observable<SelectableInput[]> {
    const url = `${this.baseApiUrl}address/callTypeDropdown?isCallSuccessful=${isCallSuccess}`;

    return this.http.get(url) as Observable<SelectableInput[]>;
  }

  saveCallNotes(callNotes: CallNotesModel, calledAddress: boolean): Observable<null> {
    const url = `${this.baseApiUrl}retrieval/saveCallNotes?calledAddress=${calledAddress}`;

    return this.http
      .post(url, callNotes) as Observable<null>;
  }

  saveCallResult(contactRecord: ContactRecord, actionSource: string) {

    const url = `${this.baseApiUrl}retrieval/saveContactRecord?actionSource=${actionSource}`;

    const headers = new HttpHeaders().set("content-type", "application/json");

    return this.http.post(url, contactRecord, { headers });

  }

  isFollowUpCall(retrievalId: number): Observable<boolean> {
    const url = `${this.baseApiUrl}retrieval/checkForFollowUpCall?retrievalId=${retrievalId}`;

    return this.http.get(url) as Observable<boolean>;

  }
}
