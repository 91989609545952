import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { AuthService } from "../../../../../auth/auth.service";
import { MessagingService } from "../../../../../core/messaging/messaging.service";
import { SeverityType } from "../../../../../core/messaging/severity-type.enum";
import { PopoverComponent } from "../../../../../shared/panel/popover/popover.component";
import { IOnUpload } from "../../../../../shared/upload/upload.component";
import { ProjectDocumentType } from "../../project-files/project-document-type.enum";
import { FileDetail } from "../models/file-detail.model";
import { MappingDocument } from "../models/mapping-document.model";
import { ProjectConfigurationService } from "../project-config.service";

@Component({
  selector: "app-iva-mapping-document",
  templateUrl: "./iva-mapping-document.component.html",
  styleUrls: ["./iva-mapping-document.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IvaMappingDocumentComponent {

  ivaMappingDocumentDetail: FileDetail;
  fileId: string;
  readonly projectDocumentTypeId = ProjectDocumentType.Project;
  @ViewChild("popover", { static: true }) private popover: PopoverComponent;
  @Output() uploadDeleteIVAFile = new EventEmitter<{documentType: string; filePath: string; ivaMappingAttributes: MappingDocument}>();
  @Output() onFocus = new EventEmitter<any>();
  hasFile = false;
  upLoadInfo: IOnUpload;

  private projectMappingDocumentAttribute1: MappingDocument;
  @Input()
  get projectMappingDocumentAttribute(): any {
    return this.projectMappingDocumentAttribute1;
  }
  set projectMappingDocumentAttribute(value: any) {
    this.projectMappingDocumentAttribute1 = value;

    if (value != null) {
    this.uploadedIVAMappingFilesDetail();
    this.changeDetector.markForCheck();
    }
  }

  attributeId: number;
  @Input() documentType: string;
  fileName: string;
  filePath: string;
  uploadDateTime: Date;
  uploadedBy: string;

  constructor(
    private service: ProjectConfigurationService,
    private authService: AuthService,
    private messagingService: MessagingService,
    private changeDetector: ChangeDetectorRef
  ) { }

  uploadedIVAMappingFilesDetail(): void {
      if (this.projectMappingDocumentAttribute1 != null) {
      this.projectMappingDocumentAttribute.uploadDateTime = new Date(`${this.projectMappingDocumentAttribute.uploadDateTime} UTC`);
      this.filePath = this.projectMappingDocumentAttribute1.filePath;
      this.hasFile = true;
    }
  }

  downloadIVAMappingFile(): void {
    this.fileId = this.filePath.substring(this.filePath.lastIndexOf("/") + 1);
    this.service.downloadFile(this.fileId, this.authService.userId, "ivamappingdocument", this.projectDocumentTypeId);
  }

  deleteIVAMappingFile(): void {
    this.projectMappingDocumentAttribute1 = null;
    this.filePath = null;
    this.hasFile = false;

    this.uploadDeleteIVAFile.emit({documentType: this.documentType, filePath: this.filePath, ivaMappingAttributes: this.projectMappingDocumentAttribute1});

    this.messagingService.showToast(`${this.documentType} deleted successfully.`, SeverityType.SUCCESS);
    this.changeDetector.markForCheck();
  }

  uploadDocument(event: IOnUpload): void {
    this.upLoadInfo = event;

    this.service.uploadIVAMappingDocument(this.upLoadInfo.formData)
            .subscribe(data => {
                if (data != null) {
                    this.projectMappingDocumentAttribute1 = data;
                    this.projectMappingDocumentAttribute1.documentType = this.documentType;
                    this.filePath = data.filePath;
                    this.messagingService.showToast(`${this.documentType} uploaded successfully.`, SeverityType.SUCCESS);
                    this.hasFile = true;
                    this.upLoadInfo.success();
                    this.uploadDeleteIVAFile.emit({documentType: this.documentType, filePath: this.filePath, ivaMappingAttributes: this.projectMappingDocumentAttribute1});
                    this.changeDetector.markForCheck();
                } else {
                    this.messagingService.showToast("Error while Document uploading, please try again.", SeverityType.ERROR);
                }
            });
}
}
