import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { AutomapperService } from "../../../../../core/automapper/automapper.service";
import { BASE_API_URL } from "../../../../../core/environment.tokens";
import { RetrievalContactHistory } from "../../retrieval-detail-contact-history.model";

@Injectable({
  providedIn: "root",
})
export class RetrievalAddressDetailContactHistoryService {
  hasCallNotesButton = new BehaviorSubject<boolean>(false);

  private refreshContactHistory = new BehaviorSubject<boolean>(false);
  refreshContactHistory$ = this.refreshContactHistory.asObservable();

  constructor(
    @Inject(BASE_API_URL) private readonly baseApiUrl: string,
    private http: HttpClient,
    private automapper: AutomapperService
  ) { }

  getContactHistoryList(contactHistory: RetrievalContactHistory): Observable<RetrievalContactHistory[]> {
    const url = `${this.baseApiUrl}Retrieval/ContactHistory`;

    return this.http.post(url, contactHistory).pipe(
      map(this.automapper.curryMany("default", "RetrievalContactHistory"))
    );
  }

  setHasCallNotesButton(value: boolean) {
    this.hasCallNotesButton.next(value);
  }

  refreshContactHistoryEvent(value: boolean) {
    this.refreshContactHistory.next(value);
  }

  retryFax(contactLogId: number, contactFax: string): Observable<string> {
    const url = `${this.baseApiUrl}fax/retry`;
    return this.http.post(url, { contactLogId, faxNumber: contactFax }) as Observable<string>;
  }
}
